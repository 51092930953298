import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"



const FDP = () => (
    <Layout>
        <Container>
            <div className="column">
                <br /><br />
                <div class="text-center">
                    {/* Картинка */}
                    <StaticImage
                        src="img/637261751506454852-b90--hbm---126-955-623.png"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="HBM"
                    />
                </div>
                {/* Заголовок */}
                <h1 class="text-center py-3">
                    СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА
                </h1>
                {/* Текст */}
                <p class="blockquote card px-4 mx-5 py-3">
                    Серия HBM - механическая зерновая сеялка для традиционного посева. Можно посеять зерновые культуры
                    стандартного размера, такие как пшеница, ячмень, овес, чечевица, нут, бобы и т. д.  Прицепная система сеялок
                    серии HBM уменьшает требуемую мощность трактора. Серия HBM соответствует
                    всем размерам запроса сельского хозяйства. Серия HBM-BABA имеет в два раза большую емкость для семян
                    и удобрений, чем стандартная HBM.</p>

                <h2 class="text-center py-3 text-black-50">
                    ПРЕИМУЩЕСТВА ПРОДУКТА
                </h2>


                <div class="row justify-content-center text-center px-5">
                    <Prodfeat text="Одинарная Дисковая Секция" />
                    <Prodfeat text="От 30 кг до 400 кг/га" />
                    <Prodfeat text="Стандартный Бункер Для Семян И Удобрений" />
                    <Prodfeat text="Рифленый Дозатор" />
                    <Prodfeat text="Прицепная Сеялка" />
                    <Prodfeat text="Гидравлическая Регулировка Глубины" />
                    <Prodfeat text="Погрузочная Платформа" />
                    <Prodfeat text="Двойной Гидравлический Маркер" />
                </div>



                {/* Преимущества */}
                <h2 class="text-center pt-3 text-black-50">
                    ОБОРУДОВАНИЕ ПРОДУКТА
                </h2>
                <div class="row px-3">

                    {/* <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261930773154788-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ АСМ-К СКЛАДЫВАЮЩАЯСЯ ПРОПАШНОЙ КУЛЬТИВАТОР. КОЛЕСО УДОБРЕНИЙ" style={{ marginBottom: `1.45rem` }} /><strong class="h4">КОЛЕСО УДОБРЕНИЙ</strong><p class="h5">Центральное колесо удобрений защищает посевы от повреждений, в отличие от боковых колес. Кроме того, во время работы легко следить за колесом.</p> </div> </div> </div> */}
                    {/* <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637267758280360451-b75-750x16---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. 7.50X16 РАЗМЕР ШИН" style={{ marginBottom: `1.45rem` }} /><strong class="h4">7.50X16 РАЗМЕР ШИН</strong><p class="h5"></p> </div> </div> </div> */}
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637267758571989810-b75-124x24---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. 12.4X24 РАЗМЕР ШИН" style={{ marginBottom: `1.45rem` }} /><strong class="h4">12.4X24 РАЗМЕР ШИН</strong><p class="h5">Система заслонок, которая может быть открыта в зависимости от размера семени, позволяет высевать не только пшеницу и ячмень, но и крупнозернистые семена (бобы) без остановки</p> </div> </div> </div>
                    {/* <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261852189134670-b75------550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. Посевное Снаряжение с Одной Трубкой" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Посевное Снаряжение с Одной Трубкой</strong><p class="h5">Благодаря простой конструкции однотрубчатый посев обеспечивает максимальную эффективность на вспаханной и влажной почвах.</p> </div> </div> </div> */}
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261853545193938-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. Посевное Снаряжение Чугунного Типа" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Посевное Снаряжение Чугунного Типа</strong><p class="h5">Благодаря надежной и простой структуре имеется возможность посева даже в дождливые осенние месяцы</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261841176624334-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. ТРАНСПОРТНАЯ СИСТЕМА" style={{ marginBottom: `1.45rem` }} /><strong class="h4">ТРАНСПОРТНАЯ СИСТЕМА</strong><p class="h5"></p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261840015164209-b75---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. Погрузочная Платформа" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Погрузочная Платформа</strong><p class="h5">Погрузочная платформа-это очень полезный способ для погрузки семян и удобрений</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637267758402786902-b75-115x80153---550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. 11.5X80-15.3 РАЗМЕР ШИН" style={{ marginBottom: `1.45rem` }} /><strong class="h4">11.5X80-15.3 РАЗМЕР ШИН</strong><p class="h5"></p> </div> </div> </div>
                    {/* <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261838640134007-b75-----550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. Невидимая Покрытие Для Удобрений" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Невидимая Покрытие Для Удобрений</strong><p class="h5">При заполнении семян и удобрений невидимая крышка предотвращает смешивание семян и удобрений.</p> </div> </div> </div> */}
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261831570576920-b75-v-------550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. V Образный Бункер Для Семян И Удобрений" style={{ marginBottom: `1.45rem` }} /><strong class="h4">V Образный Бункер Для Семян И Удобрений</strong><p class="h5">Благодаря угловому наклону V-образной формы обеспечивается легкий поток семян и удобрений в трубки. При посеве, когда количество семян и удобрений в бункере уменьшается, предотвращается скопление на дне и бункер полностью опорожняется.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261853065617737-b75---------550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. Посевное Снаряжение с Двумя Семяпроводами" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Посевное Снаряжение с Двумя Семяпроводами</strong><p class="h5">За счет конструкции с двумя семяпроводами семена и удобрения вводятся в почву через разные каналы. Причем семена вводятся на 2 см выше вводимого удобрения. Таким образом, в сухие сезоны удобрения не нанесут вреда семенам, а семена проростая будут забирать из удобрений всё больше энергии.</p> </div> </div> </div>
                    <div class="col-sm-6 col-lg-4 mt-4"> <div class="h-100 position-relative"> <div class="card-body border-dark"><StaticImage src="img/637261840803559908-b75------550-0.jpeg" width={392} quality={50} formats={["auto", "webp", "avif"]} alt="СЕРИЯ HBM МЕХАНИЧЕСКАЯ ЗЕРНОВАЯ СЕЯЛКА. Посевная Секция с Двойным Диском" style={{ marginBottom: `1.45rem` }} /><strong class="h4">Посевная Секция с Двойным Диском</strong><p class="h5"></p> </div> </div> </div>

                </div>

                <h2 class="text-center py-3 text-black-50">
                    ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                {/* Таблица */}
                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                        <thead class="background-dark-gray-ozdo"><tr>
                            <th>МОДЕЛЬ</th>
                            <th>КОЛИЧЕСТВО ДИСКОВ</th>
                            <th>РАССТОЯНИЕ МЕЖДУ ДИСКАМИ (см)</th>
                            <th>ЕМКОСТЬ БУНКЕРА ДЛЯ МАЛЕНЬКИХ СЕМЯН (л) </th>
                            <th>ЕМКОСТЬ БУНКЕРА ДЛЯ УДОБРЕНИЙ (л) </th>
                            <th>ОБЩАЯ ШИРИНА (см)</th>
                            <th>РАБОЧАЯ ШИРИНА (см)</th>
                            <th>ТРАНСПОРТНАЯ ШИРИНА (см)</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>HBM-36</td>
                                <td>36</td>
                                <td>15</td>
                                <td>750</td>
                                <td>750</td>
                                <td>650</td>
                                <td>540</td>
                                <td>300</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    </Layout>
);

export default FDP;